import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps from "/app/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import portal_vue_Km_Xx6PD_j38yqEi0AoV88mZXMuRroH27Agw6jSzUGk from "/app/plugins/portal-vue.ts";
import analytics_BhoGt0UoWwExOlzQ3gwD6b9_FJEeLUL7wOVadC7dSHk from "/app/plugins/analytics.ts";
import clientJS_client_k4b8LSOgSl0FDC8znpkykmk7E2ptsvQT4ev0jXeD0q4 from "/app/plugins/clientJS.client.ts";
import _01_user_client_steL1VBHKfR509nnCa7ZpxrN6hH595u0wYKtMKBgwA8 from "/app/plugins/01.user.client.ts";
import _02_sentry_client_r8VsRL6uaOB6niOe2KdSDlHEN_OsC8qnPLsZ1O1SNgA from "/app/plugins/02.sentry.client.ts";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/app/plugins/error-handler.ts";
import fontawesome_Q3IsgyqLBh1UKEylzguvAAGnY_M0M67ZFjQjgD7J1rI from "/app/plugins/fontawesome.ts";
import highcharts_client__fP9U_cbxhXirJWAeS5tRCJgWeqkNFOZqSB2FrLalVM from "/app/plugins/highcharts.client.ts";
import scroll_spy_o4g0u_gDnmuTcEl6uZqbAN9YsDQdMqq_zTbOMq8sfdU from "/app/plugins/scroll-spy.ts";
import vue_gtm_client_u_j0pm3ej91QcKWZ9nG91jhdx9pmjMD2YWn5PlqZqPA from "/app/plugins/vue-gtm.client.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_f3uejz4_gisdEmqr8oiqo40ZtL_ImngJsD7HSlp7_ps,
  portal_vue_Km_Xx6PD_j38yqEi0AoV88mZXMuRroH27Agw6jSzUGk,
  analytics_BhoGt0UoWwExOlzQ3gwD6b9_FJEeLUL7wOVadC7dSHk,
  clientJS_client_k4b8LSOgSl0FDC8znpkykmk7E2ptsvQT4ev0jXeD0q4,
  _01_user_client_steL1VBHKfR509nnCa7ZpxrN6hH595u0wYKtMKBgwA8,
  _02_sentry_client_r8VsRL6uaOB6niOe2KdSDlHEN_OsC8qnPLsZ1O1SNgA,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  fontawesome_Q3IsgyqLBh1UKEylzguvAAGnY_M0M67ZFjQjgD7J1rI,
  highcharts_client__fP9U_cbxhXirJWAeS5tRCJgWeqkNFOZqSB2FrLalVM,
  scroll_spy_o4g0u_gDnmuTcEl6uZqbAN9YsDQdMqq_zTbOMq8sfdU,
  vue_gtm_client_u_j0pm3ej91QcKWZ9nG91jhdx9pmjMD2YWn5PlqZqPA
]