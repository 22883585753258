
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about3BeDYJHafdz3_450F8I84XDeTlPVmsANEPp0qlI_45udm9EMeta } from "/app/pages/about.vue?macro=true";
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as loginKGJhNRZhH4FU_45RnHfId5ZCGRhKzPnQPbdAbdNd47TwsMeta } from "/app/pages/login.vue?macro=true";
import { default as signupXDZ9vem3RhITR7dUmFJxupvJgksTCjysSig_ObPaBwEMeta } from "/app/pages/signup.vue?macro=true";
import { default as statusGxyJAiT6T08i4QnaacTYMdHkpZ5UFlYXwaNfFcltlQwMeta } from "/app/pages/status.vue?macro=true";
import { default as careersadRifqKj5KBl1A0jECsFOk1kbe37aRjkPIRXUdW_45JV8Meta } from "/app/pages/careers.vue?macro=true";
import { default as thank_45younfXQQV_45m_nd4_hppzDzD5gaDFCMO3Gis_45vsHgFrgkSEMeta } from "/app/pages/thank-you.vue?macro=true";
import { default as get_45startedX5rPuZsg9h1jB3NCwbkN7LJswbIxYvRh7KuJtkgdp1gMeta } from "/app/pages/get-started.vue?macro=true";
import { default as indexyaqIB5r_otqI_PLIc1ZIvlsOA0aX8wZQfqQ2sxJ9NSIMeta } from "/app/pages/legal/index.vue?macro=true";
import { default as maintenanceT_45jg7_pWb39JSa1hwfoRit1d5KL66Qjib5lqwfdmyV8Meta } from "/app/pages/maintenance.vue?macro=true";
import { default as profileRzaLXe48fgBOIVJMfkhkJBaYQGtPw_7WQJHNGvhmFcYMeta } from "/app/pages/user/profile.vue?macro=true";
import { default as privacyN_45OgLlUD_qeUb0gRxeQMuZ9Wz1vld09XF6rpynCcDnkMeta } from "/app/pages/legal/privacy.vue?macro=true";
import { default as _91_91id_93_93AYgPkq9Hwy0Z0FAyqrAfClOLixR_AuaZJ9wBzGcOC8UMeta } from "/app/pages/meeting/[[id]].vue?macro=true";
import { default as indexdsXC1l4tb_YVrgeIr8UL8Dpd0MGw0bYSNSy2QTJnmrEMeta } from "/app/pages/meetings/index.vue?macro=true";
import { default as password_45resetxRvOLzyJHyLM38zYxJqR6kzFL8eM52wCE7v8EBM5xbwMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as forgot_45passwordgdzNcmliYSc_RqJLDFi4QKABybrSV1OTdLl0SK_45V07UMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as school_45of_45miningFizv2akY6lyA3Xr6nYeosmq1zTSc3wTcJoBecLiK750Meta } from "/app/pages/school-of-mining.vue?macro=true";
import { default as _91_91id_93_93dzK1m4HkS7OxVwQMwSxO7jJXxrqRaZxwgEBjr_45rHggwMeta } from "/app/pages/recordings/[[id]].vue?macro=true";
import { default as _91_91token_93_93abJLYplxbql2RZCOsVuovaBcFEAOdYVnNUCs47zuwxEMeta } from "/app/pages/activate/[[token]].vue?macro=true";
import { default as indexQI1b5QhJaL2RZV9pwZ0pnorj_45bgNSKrvzKa1ic2JtAQMeta } from "/app/pages/decks/[[id]]/index.vue?macro=true";
import { default as _91_91id_93_93uqEY4I_i9h9aoVqerfPPr_TSnnwiySqUNGXrWzgCOTQMeta } from "/app/pages/embed/decks/[[id]].vue?macro=true";
import { default as investor_45solutions3I_P5pUoDISr1R6j2UVt9XddAlzWvmKi9ZJm7wsw7ckMeta } from "/app/pages/investor-solutions.vue?macro=true";
import { default as _91_91id_93_93ztrhTlB6oxG91Xlri1f7Rpo1eidBslE_W68RJj3d9VQMeta } from "/app/pages/editor/decks/[[id]].vue?macro=true";
import { default as _91_91id_93_93J0rfIpTcGpJRXZfpXUXLaq3qH5tn1r0LP_45eTj9SuRK0Meta } from "/app/pages/embed/videos/[[id]].vue?macro=true";
import { default as cookie_45noticePpA2_c7BLzXLcbDCzA38bvG_rwKWf_45hcUgQkORCxwFoMeta } from "/app/pages/legal/cookie-notice.vue?macro=true";
import { default as _91id_935jV3mX8x_454ewlQAS9jJ7YyKoYhPzbzM1V3G75vNbIdUMeta } from "/app/pages/press-releases/[id].vue?macro=true";
import { default as _91_91id_93_93u1GnnsL6SQFmLnY_IMHsOepBW9Oni5_45yMO4LpQeJb_45sMeta } from "/app/pages/editor/models/[[id]].vue?macro=true";
import { default as _91_91id_93_93y7H_Syg8j0mAu62BJgBRYR1R_NmtLWVne6eWmvAqhZoMeta } from "/app/pages/meetings/book/[[id]].vue?macro=true";
import { default as indexP1LHs69zWprRG07PRqvsyDNjgxv_45lMG1zlggHXinC3QMeta } from "/app/pages/press-releases/index.vue?macro=true";
import { default as feedbackSwivbNMCNtZqArHDBPRSKrqvmZix_vTq839_45gr1ocjMMeta } from "/app/pages/decks/[[id]]/feedback.vue?macro=true";
import { default as _91_91endpoint_93_93cWti6CqTvFeX4SdSSA2oY7Nc_dOTGT6ytjQngVMQvXcMeta } from "/app/pages/discover/[[endpoint]].vue?macro=true";
import { default as indexSlse2Y9aU3w5kwbJHmIX3_zhBS3hS513yvFnxa4YA50Meta } from "/app/pages/companies/[[id]]/index.vue?macro=true";
import { default as indexWNa2x06HK3Oh6PfMMT_45ZSGTWSezWu83l2x11_45_45wSLoAMeta } from "/app/pages/data-files/[[id]]/index.vue?macro=true";
import { default as mining_45company_45solutionsVqYerAybEwKNyPLZR_fKxJu1BrZSbYQJ0LMXQn3LL6cMeta } from "/app/pages/mining-company-solutions.vue?macro=true";
import { default as introTwpud8GscmGGcCvCtowEpwrIkp4sBKgHjPD6yfWINQkMeta } from "/app/pages/meetings/book/setup/intro.vue?macro=true";
import { default as _91_91id_93_930284M2tsd9MNqfSFThrgDaUbKUdZNi7MniczhDSomKcMeta } from "/app/pages/embed/decks/preview/[[id]].vue?macro=true";
import { default as _91_91id_93_934124NzrQwxJ4ZyhdJSo_CxhKkdf8HevRVuMFVj7hxQwMeta } from "/app/pages/meetings/recordings/[[id]].vue?macro=true";
import { default as activities7FDjqX9_grOHiqqwXV0RqibBgow7AUAFXSk7w70YV7QMeta } from "/app/pages/companies/[[id]]/activities.vue?macro=true";
import { default as _91_91id_93_938ES2WsTgbuawP_45H6k80Yu1IRD_45fS4AOE606YMEb6qG8Meta } from "/app/pages/embed/videos/preview/[[id]].vue?macro=true";
import { default as acceptable_45use_45policyTIwU2tCT7dX8stMwHCZQv3LAs5kH9plfSK05cTz6xp4Meta } from "/app/pages/legal/acceptable-use-policy.vue?macro=true";
import { default as user_45terms_45of_45servicey_452db8vdPZZvSN2VcXvxacXGo9CPtazyFnRELtAMniEMeta } from "/app/pages/legal/user-terms-of-service.vue?macro=true";
import { default as client_45terms_45of_45servicexnzr84YRUE74VTEet_kgU1sGsg00N7TefaKcIVvDu2IMeta } from "/app/pages/legal/client-terms-of-service.vue?macro=true";
import { default as newgpPPXZHFKjLxLeGPvlLGYAUXx39OHJBsqIvU7wJGN3oMeta } from "/app/pages/data-files/[[id]]/settings/new.vue?macro=true";
import { default as share_45linkIB6uPVPmFY_fuNJnOUFdpR4_fbOVM7Rnd_45fKFi53_NAMeta } from "/app/pages/meetings/book/setup/share-link.vue?macro=true";
import { default as availabilityhhkGXiHxwArVrtP6BISG5YZISI3iQ3Et_45ce11K3zkAUMeta } from "/app/pages/meetings/book/setup/availability.vue?macro=true";
import { default as sync_45calendarxP_45BENEAHGRk_45MgBQmkiAkg5wuQ0V2rF5elFOm44jV4Meta } from "/app/pages/meetings/book/setup/sync-calendar.vue?macro=true";
import { default as index6T7PdJ7IvTqf9JFcvpZAGjzO8eUggU902iWY8ARBokIMeta } from "/app/pages/data-files/[[id]]/upload/[id]/index.vue?macro=true";
import { default as copyright_45and_45ip_45infringementwe_45_lqvDdbdXqjwGl0uibYsArR2jydlD96EoNaNgRU4Meta } from "/app/pages/legal/copyright-and-ip-infringement.vue?macro=true";
import { default as congratulationsMJ86e_45kH7vQ6Aa9F_45VkLp2y_457wdDgs_45wmQqkT7MAhB8Meta } from "/app/pages/meetings/book/setup/congratulations.vue?macro=true";
import { default as meeting_45detailsw63KDbIXPLgzqD5YZ78pYAQMxK12L2wZYFVmqZ_GDpYMeta } from "/app/pages/meetings/book/setup/meeting-details.vue?macro=true";
import { default as indexXmyZ39QMHzehfIlOE4Ol1BSIjOKj9an4VE0iZ_45oVvZoMeta } from "/app/pages/data-files/[[id]]/multi-upload/index.vue?macro=true";
import { default as indexCprefaqbc8D_kXtmC_45j5GClLqlWBfJsIgaZFYCk0KosMeta } from "/app/pages/data-files/[[id]]/settings/[id]/index.vue?macro=true";
import { default as indexSnA3N0T3M8s3aSvctB7dsjJgQjt85jeZpKlo0cfSp_wMeta } from "/app/pages/data-files/[[id]]/core-images/[id]/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/app/pages/signup.vue")
  },
  {
    name: "status",
    path: "/status",
    component: () => import("/app/pages/status.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/app/pages/careers.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/app/pages/thank-you.vue")
  },
  {
    name: "get-started",
    path: "/get-started",
    component: () => import("/app/pages/get-started.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/app/pages/legal/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceT_45jg7_pWb39JSa1hwfoRit1d5KL66Qjib5lqwfdmyV8Meta || {},
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "user-profile",
    path: "/user/profile",
    component: () => import("/app/pages/user/profile.vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    component: () => import("/app/pages/legal/privacy.vue")
  },
  {
    name: "meeting-id",
    path: "/meeting/:id?",
    meta: _91_91id_93_93AYgPkq9Hwy0Z0FAyqrAfClOLixR_AuaZJ9wBzGcOC8UMeta || {},
    component: () => import("/app/pages/meeting/[[id]].vue")
  },
  {
    name: "meetings",
    path: "/meetings",
    meta: indexdsXC1l4tb_YVrgeIr8UL8Dpd0MGw0bYSNSy2QTJnmrEMeta || {},
    component: () => import("/app/pages/meetings/index.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/app/pages/password-reset.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/app/pages/forgot-password.vue")
  },
  {
    name: "school-of-mining",
    path: "/school-of-mining",
    component: () => import("/app/pages/school-of-mining.vue")
  },
  {
    name: "recordings-id",
    path: "/recordings/:id?",
    meta: _91_91id_93_93dzK1m4HkS7OxVwQMwSxO7jJXxrqRaZxwgEBjr_45rHggwMeta || {},
    component: () => import("/app/pages/recordings/[[id]].vue")
  },
  {
    name: "activate-token",
    path: "/activate/:token?",
    component: () => import("/app/pages/activate/[[token]].vue")
  },
  {
    name: "decks-id",
    path: "/decks/:id?",
    meta: indexQI1b5QhJaL2RZV9pwZ0pnorj_45bgNSKrvzKa1ic2JtAQMeta || {},
    component: () => import("/app/pages/decks/[[id]]/index.vue")
  },
  {
    name: "embed-decks-id",
    path: "/embed/decks/:id?",
    meta: _91_91id_93_93uqEY4I_i9h9aoVqerfPPr_TSnnwiySqUNGXrWzgCOTQMeta || {},
    component: () => import("/app/pages/embed/decks/[[id]].vue")
  },
  {
    name: "investor-solutions",
    path: "/investor-solutions",
    component: () => import("/app/pages/investor-solutions.vue")
  },
  {
    name: "editor-decks-id",
    path: "/editor/decks/:id?",
    meta: _91_91id_93_93ztrhTlB6oxG91Xlri1f7Rpo1eidBslE_W68RJj3d9VQMeta || {},
    component: () => import("/app/pages/editor/decks/[[id]].vue")
  },
  {
    name: "embed-videos-id",
    path: "/embed/videos/:id?",
    meta: _91_91id_93_93J0rfIpTcGpJRXZfpXUXLaq3qH5tn1r0LP_45eTj9SuRK0Meta || {},
    component: () => import("/app/pages/embed/videos/[[id]].vue")
  },
  {
    name: "legal-cookie-notice",
    path: "/legal/cookie-notice",
    component: () => import("/app/pages/legal/cookie-notice.vue")
  },
  {
    name: "press-releases-id",
    path: "/press-releases/:id()",
    component: () => import("/app/pages/press-releases/[id].vue")
  },
  {
    name: "editor-models-id",
    path: "/editor/models/:id?",
    meta: _91_91id_93_93u1GnnsL6SQFmLnY_IMHsOepBW9Oni5_45yMO4LpQeJb_45sMeta || {},
    component: () => import("/app/pages/editor/models/[[id]].vue")
  },
  {
    name: "meetings-book-id",
    path: "/meetings/book/:id?",
    meta: _91_91id_93_93y7H_Syg8j0mAu62BJgBRYR1R_NmtLWVne6eWmvAqhZoMeta || {},
    component: () => import("/app/pages/meetings/book/[[id]].vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    component: () => import("/app/pages/press-releases/index.vue")
  },
  {
    name: "decks-id-feedback",
    path: "/decks/:id?/feedback",
    component: () => import("/app/pages/decks/[[id]]/feedback.vue")
  },
  {
    name: "discover-endpoint",
    path: "/discover/:endpoint?",
    component: () => import("/app/pages/discover/[[endpoint]].vue")
  },
  {
    name: "companies-id",
    path: "/companies/:id?",
    component: () => import("/app/pages/companies/[[id]]/index.vue")
  },
  {
    name: "data-files-id",
    path: "/data-files/:id?",
    meta: indexWNa2x06HK3Oh6PfMMT_45ZSGTWSezWu83l2x11_45_45wSLoAMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/index.vue")
  },
  {
    name: "mining-company-solutions",
    path: "/mining-company-solutions",
    component: () => import("/app/pages/mining-company-solutions.vue")
  },
  {
    name: "meetings-book-setup-intro",
    path: "/meetings/book/setup/intro",
    meta: introTwpud8GscmGGcCvCtowEpwrIkp4sBKgHjPD6yfWINQkMeta || {},
    component: () => import("/app/pages/meetings/book/setup/intro.vue")
  },
  {
    name: "embed-decks-preview-id",
    path: "/embed/decks/preview/:id?",
    meta: _91_91id_93_930284M2tsd9MNqfSFThrgDaUbKUdZNi7MniczhDSomKcMeta || {},
    component: () => import("/app/pages/embed/decks/preview/[[id]].vue")
  },
  {
    name: "meetings-recordings-id",
    path: "/meetings/recordings/:id?",
    meta: _91_91id_93_934124NzrQwxJ4ZyhdJSo_CxhKkdf8HevRVuMFVj7hxQwMeta || {},
    component: () => import("/app/pages/meetings/recordings/[[id]].vue")
  },
  {
    name: "companies-id-activities",
    path: "/companies/:id?/activities",
    component: () => import("/app/pages/companies/[[id]]/activities.vue")
  },
  {
    name: "embed-videos-preview-id",
    path: "/embed/videos/preview/:id?",
    meta: _91_91id_93_938ES2WsTgbuawP_45H6k80Yu1IRD_45fS4AOE606YMEb6qG8Meta || {},
    component: () => import("/app/pages/embed/videos/preview/[[id]].vue")
  },
  {
    name: "legal-acceptable-use-policy",
    path: "/legal/acceptable-use-policy",
    component: () => import("/app/pages/legal/acceptable-use-policy.vue")
  },
  {
    name: "legal-user-terms-of-service",
    path: "/legal/user-terms-of-service",
    component: () => import("/app/pages/legal/user-terms-of-service.vue")
  },
  {
    name: "legal-client-terms-of-service",
    path: "/legal/client-terms-of-service",
    component: () => import("/app/pages/legal/client-terms-of-service.vue")
  },
  {
    name: "data-files-id-settings-new",
    path: "/data-files/:id?/settings/new",
    meta: newgpPPXZHFKjLxLeGPvlLGYAUXx39OHJBsqIvU7wJGN3oMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/settings/new.vue")
  },
  {
    name: "meetings-book-setup-share-link",
    path: "/meetings/book/setup/share-link",
    meta: share_45linkIB6uPVPmFY_fuNJnOUFdpR4_fbOVM7Rnd_45fKFi53_NAMeta || {},
    component: () => import("/app/pages/meetings/book/setup/share-link.vue")
  },
  {
    name: "meetings-book-setup-availability",
    path: "/meetings/book/setup/availability",
    meta: availabilityhhkGXiHxwArVrtP6BISG5YZISI3iQ3Et_45ce11K3zkAUMeta || {},
    component: () => import("/app/pages/meetings/book/setup/availability.vue")
  },
  {
    name: "meetings-book-setup-sync-calendar",
    path: "/meetings/book/setup/sync-calendar",
    meta: sync_45calendarxP_45BENEAHGRk_45MgBQmkiAkg5wuQ0V2rF5elFOm44jV4Meta || {},
    component: () => import("/app/pages/meetings/book/setup/sync-calendar.vue")
  },
  {
    name: "data-files-id-upload-id",
    path: "/data-files/:id?/upload/:id()",
    meta: index6T7PdJ7IvTqf9JFcvpZAGjzO8eUggU902iWY8ARBokIMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/upload/[id]/index.vue")
  },
  {
    name: "legal-copyright-and-ip-infringement",
    path: "/legal/copyright-and-ip-infringement",
    component: () => import("/app/pages/legal/copyright-and-ip-infringement.vue")
  },
  {
    name: "meetings-book-setup-congratulations",
    path: "/meetings/book/setup/congratulations",
    meta: congratulationsMJ86e_45kH7vQ6Aa9F_45VkLp2y_457wdDgs_45wmQqkT7MAhB8Meta || {},
    component: () => import("/app/pages/meetings/book/setup/congratulations.vue")
  },
  {
    name: "meetings-book-setup-meeting-details",
    path: "/meetings/book/setup/meeting-details",
    meta: meeting_45detailsw63KDbIXPLgzqD5YZ78pYAQMxK12L2wZYFVmqZ_GDpYMeta || {},
    component: () => import("/app/pages/meetings/book/setup/meeting-details.vue")
  },
  {
    name: "data-files-id-multi-upload",
    path: "/data-files/:id?/multi-upload",
    meta: indexXmyZ39QMHzehfIlOE4Ol1BSIjOKj9an4VE0iZ_45oVvZoMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/multi-upload/index.vue")
  },
  {
    name: "data-files-id-settings-id",
    path: "/data-files/:id?/settings/:id()",
    meta: indexCprefaqbc8D_kXtmC_45j5GClLqlWBfJsIgaZFYCk0KosMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/settings/[id]/index.vue")
  },
  {
    name: "data-files-id-core-images-id",
    path: "/data-files/:id?/core-images/:id()",
    meta: indexSnA3N0T3M8s3aSvctB7dsjJgQjt85jeZpKlo0cfSp_wMeta || {},
    component: () => import("/app/pages/data-files/[[id]]/core-images/[id]/index.vue")
  }
]